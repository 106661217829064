<template>
  <div class="columns is-centered">
    <div class="column" style="max-width:720px">
      <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false" />
      <div v-if="loading" class="is-size-2">
        Enviando....
      </div>
      <div v-show="!loading && !error">
        <div class="is-size-4">
          Concluido
        </div>
        <div class="is-size-5" style="margin-top:20px">
          {{msg}}
        </div>
        <div class="has-text-weight-semibold is-size-4" style="margin-top:20px">
          {{ protocolo }}
        </div>
        <v-if>{{infr}}</v-if>
      </div>
      <div v-show="error">
        <p class="is-size-4">
          {{ mensagemErro != '' ? mensagemErro : 'Ocorreu um erro ao enviar o seu recurso. Por favor tente novamente mais tarde.' }}
        </p>
      </div>
      <router-link to="/infracoes">
        <b-button
          v-if="!loading"
          label="Sair"
          type="is-primary"
          style="margin-top:100px"
        />
      </router-link>
    </div>
  </div>
</template>
<script>
import defaultAxios from '../../../plugin/axios'
export default {
  name: 'StepEnviarRecurso',
  props: {
    infracao: {
      type: Object,
      required: true
    },
    recurso: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      protocolo: '',
      msg: '',
      error: false,
      mensagemErro: ''
    }
  },
  beforeMount () {
    this.enviarIndicacao()
  },
  methods: {
    async enviarIndicacao () {
      const data = await montaRecurso(this.recurso)
      await defaultAxios.post(
        `${process.env.VUE_APP_BASE_URL}/recurso/${process.env.VUE_APP_CLIENTID}/${this.infracao.idInfracao}`,
        data).then((ret) => {
        this.loading = false
        this.protocolo = ret.data.protocolo
        this.msg = ret.data.msg
      }).catch(erro => {
        this.mensagemErro = erro.response.data[0].value
        this.loading = false
        this.error = true
      })
    }
  }
}
async function montaRecurso (recurso) {
  const [docIdentificacaoB64, docNotifOuPenalidadeB64, formularioAssinadoB64, docResponsabilidadeB64, docComprobatorioB64] = await Promise.all([
    getImageBase64(recurso.imagens.docIdentificacao),
    getImageBase64(recurso.imagens.docNotifOuPenalidade),
    getImageBase64(recurso.imagens.formularioAssinado),
    getImageBase64(recurso.imagens.docResponsabilidade),
    getImageBase64(recurso.imagens.docComprobatorio)
  ])

  return {
    tipoRecursoSolicitacao: recurso.tipoRecursoSolicitacao,
    ehProprietario: recurso.ehProprietario,
    semDocResponsabilidade: recurso.semDocResponsabilidade,
    semDocComprobatorio: recurso.semDocComprobatorio,
    nomeInteressado: recurso.nomeInteressado,
    enderecoLogradouro: recurso.enderecoLogradouro,
    enderecoNumero: recurso.enderecoNumero,
    enderecoComplemento: recurso.enderecoComplemento,
    enderecoBairro: recurso.enderecoBairro,
    enderecoCep: recurso.enderecoCep,
    enderecoMunicio: recurso.enderecoMunicio,
    enderecoUf: recurso.enderecoUf,
    placa: recurso.placa,
    ait: recurso.ait,
    celularInteressado: recurso.celular ? recurso.celular.replace(/\D/g, '') : null,
    emailCondutor: recurso.email ? recurso.email : null,
    imagens: {
      docIdentificacao: docIdentificacaoB64,
      docNotifOuPenalidade: docNotifOuPenalidadeB64,
      formularioAssinado: formularioAssinadoB64,
      docResponsabilidade: docResponsabilidadeB64,
      docComprobatorio: docComprobatorioB64
    }
  }
}
function getImageBase64 (file) {
  return new Promise((resolve, reject) => {
    if (file === null) {
      resolve('')
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}
</script>
