<template>
  <section class="">
    <div class="columns is-centered">
      <div class="column is-3">
        <b-field
          label="Documento:"
          class="mb-0"
        >
          <b-select
            v-model="recurso.documento"
            @change.native="trocarPreview($event)"
            placeholder="Documento"
            name="documento"
          >
            <option
              v-for="documento in tiposDocumento"
              :key="documento"
              :value="documento"
            >
              {{ documento }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <form class="column" @submit.prevent="validate()">
      <div class="columns is-centered">
        <div class="image-file is-narrow">
          <figure class="imagem">
            <img v-if="!imagem" :src="require(`../../../assets/${caminhoImagemExibicao}`)">
            <img v-if="imageB64" :src="imageB64">
          </figure>
          <object
            v-if="pdfB64"
            type="application/pdf"
            :data="pdfB64"
            style="width: 500px; height:500px"
          />
        </div>
      </div>
      <div class="columns is-centered">
        <b-field
          :type="fieldType"
          :message="message"
          class="file column is-narrow"
        >
        <span class="error file column is-narrow" v-if="fileError">
          Arquivo deve ter no máximo 1,5mb
        </span>
          <b-upload
            v-model="file"
            accept=".jpg, .png, .pdf, .jpeg"
            @input="setImage"
          >
            <a class="button is-primary">
              <b-icon icon="upload" />
              <span>Carregar Imagem</span>
            </a>
          </b-upload>
          <span v-if="file" class="file-name">
            {{ file.name }}
          </span>
        </b-field>
      </div>
    </form>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'StepUploadImagemDocumento',
  props: {
    recurso: {
      type: Object,
      required: true
    },
    imagem: {
      type: null,
      required: true
    }
  },
  data () {
    return {
      file: null,
      fileError: false,
      imageB64: null,
      pdfB64: null,
      message: '',
      fieldType: '',
      tiposDocumento: ['Registro Nacional (RG)', 'Carteira Nacional de Habilitação(CNH)', 'CPF'],
      caminhoImagemExibicao: 'default_image.png'
    }
  },
  validations: {
    recurso: {
      documento: { required }
    }
  },
  beforeMount () {
    if (this.imagem) {
      this.file = this.imagem
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        if (this.imagem && !this.fileError && !this.$v.$invalid) {
          this.fieldType = ''
          this.message = ''
          resolve(true)
        } else {
          this.fieldType = 'is-danger'
          this.message = 'Documento de identificação é necessário'
        }
      })
    },
    setImage (file) {
      if (file.size > 1500000) { // Imagem deve ter no maximo 1,5mb
        this.fileError = true
        this.file = null
        return
      }

      this.fileError = false
      this.imageB64 = window.URL.createObjectURL(file)
      this.pdfB64 = null
      this.fieldType = ''
      this.message = ''

      if (file.type === 'application/pdf') {
        this.pdfB64 = window.URL.createObjectURL(file)
        this.imageB64 = null
      }

      this.$emit('atualiza-imagem', file)
    },
    trocarPreview (event) {
      switch (event.target.value) {
        case 'Registro Nacional (RG)':
          this.caminhoImagemExibicao = 'upload-rg.jpg'
          break
        case 'Carteira Nacional de Habilitação(CNH)':
          this.caminhoImagemExibicao = 'upload-cnh.jpg'
          break
        default:
          this.caminhoImagemExibicao = 'default_image.png'
          break
      }
    }
  }
}
</script>
<style>
  .imagem {
    max-width: 256px;
    max-height: 512px;
  }

  .error {
    color: #d11313;
    font-size: 15px;
  }
</style>
