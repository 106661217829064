var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"column"},[_c('form',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Auto de Infração:"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"disabled":""},on:{"change":function($event){return _vm.$v.recurso.ait.$touch()}},model:{value:(_vm.recurso.ait),callback:function ($$v) {_vm.$set(_vm.recurso, "ait", $$v)},expression:"recurso.ait"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Tipo de Solicitação:"}},[_c('b-select',{attrs:{"placeholder":"selecione","name":"tipoRecursoSolicitacao"},on:{"change":function($event){return _vm.$v.recurso.tipoRecursoSolicitacao.$touch()}},model:{value:(_vm.recurso.tipoRecursoSolicitacao),callback:function ($$v) {_vm.$set(_vm.recurso, "tipoRecursoSolicitacao", $$v)},expression:"recurso.tipoRecursoSolicitacao"}},_vm._l((_vm.tiposSolicitacao),function(solicitacao){return _c('option',{key:solicitacao.cod,domProps:{"value":solicitacao.cod}},[_vm._v(" "+_vm._s(solicitacao.desc)+" ")])}),0)],1),(
              !_vm.$v.recurso.tipoRecursoSolicitacao.required &&
                _vm.$v.recurso.tipoRecursoSolicitacao.$dirty
            )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Nome Completo"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"name":"Nome"},on:{"change":function($event){return _vm.$v.recurso.nomeInteressado.$touch()}},model:{value:(_vm.recurso.nomeInteressado),callback:function ($$v) {_vm.$set(_vm.recurso, "nomeInteressado", $$v)},expression:"recurso.nomeInteressado"}})],1),(
                !_vm.$v.recurso.nomeInteressado.required &&
                  _vm.$v.recurso.nomeInteressado.$dirty
              )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Endereço"}},[_c('b-input',{attrs:{"name":"Logradouro"},on:{"change":function($event){return _vm.$v.recurso.enderecoLogradouro.$touch()}},model:{value:(_vm.recurso.enderecoLogradouro),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoLogradouro", $$v)},expression:"recurso.enderecoLogradouro"}})],1),(
                !_vm.$v.recurso.enderecoLogradouro.required &&
                  _vm.$v.recurso.enderecoLogradouro.$dirty
              )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"columns mt-2"},[_c('div',{staticClass:"column is-2"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Número"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"name":"Numero"},on:{"change":function($event){return _vm.$v.recurso.enderecoNumero.$touch()}},model:{value:(_vm.recurso.enderecoNumero),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoNumero", $$v)},expression:"recurso.enderecoNumero"}})],1),(
                  !_vm.$v.recurso.enderecoNumero.required &&
                    _vm.$v.recurso.enderecoLogradouro.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e(),(
                  !_vm.$v.recurso.enderecoNumero.numeric &&
                    _vm.$v.recurso.enderecoNumero.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Apenas números ")]):_vm._e()],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Complemento"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"name":"Complemento"},on:{"change":function($event){return _vm.$v.recurso.enderecoComplemento.$touch()}},model:{value:(_vm.recurso.enderecoComplemento),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoComplemento", $$v)},expression:"recurso.enderecoComplemento"}})],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"CEP"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"name":"CEP"},on:{"change":function($event){return _vm.$v.recurso.enderecoCep.$touch()}},model:{value:(_vm.recurso.enderecoCep),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoCep", $$v)},expression:"recurso.enderecoCep"}})],1),(
                  !_vm.$v.recurso.enderecoCep.required &&
                    _vm.$v.recurso.enderecoCep.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e(),(!_vm.$v.recurso.enderecoCep.minLength)?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" O CEP está incompleto ")]):_vm._e()],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Bairro"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"name":"Bairro"},on:{"change":function($event){return _vm.$v.recurso.enderecoBairro.$touch()}},model:{value:(_vm.recurso.enderecoBairro),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoBairro", $$v)},expression:"recurso.enderecoBairro"}})],1),(
                  !_vm.$v.recurso.enderecoBairro.required &&
                    _vm.$v.recurso.enderecoBairro.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-1"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"UF"}},[_c('b-select',{attrs:{"placeholder":"UF","name":"UFEndereco"},on:{"change":function($event){return _vm.$v.recurso.enderecoUf.$touch()}},model:{value:(_vm.recurso.enderecoUf),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoUf", $$v)},expression:"recurso.enderecoUf"}},_vm._l((_vm.estados),function(estado){return _c('option',{key:estado,domProps:{"value":estado}},[_vm._v(" "+_vm._s(estado)+" ")])}),0)],1),(
                  !_vm.$v.recurso.enderecoUf.required &&
                    _vm.$v.recurso.enderecoUf.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('div',{staticClass:"column is-5"},[_c('b-field',{staticClass:"mb-0 is-fullwidth",attrs:{"label":"Município"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('div',{staticClass:"control is-expanded"},[_c('b-select',{staticClass:"is-fullwidth",attrs:{"name":"Município","disabled":!_vm.recurso.enderecoUf},on:{"change":function($event){return _vm.$v.recurso.enderecoMunicio.$touch()}},model:{value:(_vm.recurso.enderecoMunicio),callback:function ($$v) {_vm.$set(_vm.recurso, "enderecoMunicio", $$v)},expression:"recurso.enderecoMunicio"}},[_c('option',{staticStyle:{"width":"100%","max-width":"100%"},attrs:{"disabled":"","hidden":"","value":""}},[_vm._v(" texto aleatório apenas para bater o tamanho correto d dropdown ")]),_vm._l((_vm.municipios),function(municipio){return _c('option',{key:municipio.dsMunic,domProps:{"value":municipio.dsMunic}},[_vm._v(" "+_vm._s(municipio.dsMunic)+" ")])})],2)],1)]),(
                  !_vm.$v.recurso.enderecoMunicio.required &&
                    _vm.$v.recurso.enderecoMunicio.$dirty
                )?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"Celular"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"name":"celular"},on:{"change":function($event){return _vm.$v.recurso.celular.$touch()}},model:{value:(_vm.recurso.celular),callback:function ($$v) {_vm.$set(_vm.recurso, "celular", $$v)},expression:"recurso.celular"}})],1),(!_vm.$v.recurso.celular.minLength)?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" Número inválido ")]):_vm._e()],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{staticClass:"mb-0",attrs:{"label":"E-mail"},on:{"change":function($event){return _vm.$emit('change', _vm.recurso)}}},[_c('b-input',{attrs:{"name":"Município"},on:{"change":function($event){return _vm.$v.recurso.email.$touch()}},model:{value:(_vm.recurso.email),callback:function ($$v) {_vm.$set(_vm.recurso, "email", $$v)},expression:"recurso.email"}})],1),(!_vm.$v.recurso.email.email && _vm.$v.recurso.email.$dirty)?_c('div',{staticClass:"error mt-1 mb-1"},[_vm._v(" E-mail inválido ")]):_vm._e()],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }