<template>
  <section class="container">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title column">
          <span class="is-size-4 has-text-weight-normal">Solicitação de Recurso</span>
          <b-tooltip label="Cancelar Solicitação de Recurso" class="is-pulled-right">
            <b-button type="is-primary" inverted rounded @click="cancel">
              <b-icon icon="close-circle" size="is-medium" />
            </b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="card-content">
        <div class="steps">
          <div
            v-for="(step, index) in steps"
            :key="index"
            :class="{
              'step-item': true,
              'is-active': step.isActive,
              'is-completed': step.isCompleted
            }"
          >
            <div class="step-marker">
              {{ step.label }}
            </div>
            <div class="step-details">
              {{ step.detail }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="step-content is-active">
          <step-cadastro-dados
            v-show="currStep === 1"
            ref="step1"
            :recurso="recurso"
            :tiposSolicitacaoRecurso="tiposSolicitacaoRecurso"
          />
          <step-upload-imagem-documento
            v-show="currStep === 2"
            ref="step2"
            :imagem="recurso.imagens.docIdentificacao"
            :recurso="recurso"
            @atualiza-imagem="recurso.imagens.docIdentificacao = $event"
          />
          <step-upload-imagem
            v-show="currStep === 3"
            ref="step3"
            :imagem="recurso.imagens.docNotifOuPenalidade"
            title="Documento da Notificação de Autuação ou Notificação de Penalidade"
            description=""
            imagemExibicao="upload-crlv.jpg"
            @atualiza-imagem="recurso.imagens.docNotifOuPenalidade = $event"
          />
          <step-upload-imagem
            v-show="currStep === 4"
            ref="step4"
            :imagem="recurso.imagens.formularioAssinado"
            title="Formulário Assinado"
            description="Formulário da alegação do recurso preenchido e assinado."
            imagemExibicao="upload-formulario.jpg"
            @atualiza-imagem="recurso.imagens.formularioAssinado = $event"
          />
          <step-upload-imagem
            v-show="currStep === 5"
            ref="step5"
            :optional="true"
            :imagem="recurso.imagens.docResponsabilidade"
            title="Documento de Procuração Especial"
            description="Procuração especial para penalidade recorrida, com firma reconhecida em cartório, no caso do interessado estar sendo representado por terceiro."
            imagemExibicao="default_image.png"
            @atualiza-imagem="recurso.imagens.docResponsabilidade= $event"
            @sem-imagem="semImagem"
          />
          <step-upload-imagem
              v-show="currStep === 6"
              ref="step6"
              :optional="true"
              :imagem="recurso.imagens.docComprobatorio"
              :title="recurso.tipoRecursoSolicitacao === 'A' ? 'Documento Comprobatório' : 'Documento Comprobatório'"
              :description="recurso.tipoRecursoSolicitacao === 'A' ? '<b>Para solicitação de advertência: Envie o Documento de pesquisa de pontuação de CNH (prontuário)</b>' : 'Documento Comprobatório opcional.'"
              imagemExibicao="default_image.png"
              @atualiza-imagem="recurso.imagens.docComprobatorio = $event"
              @sem-imagem="semImagemComprobatorio"
            >
          </step-upload-imagem>
          <step-confirmar
            v-if="currStep === 7"
            ref="step7"
            :infracao="infracao"
            :recurso="recurso"
          />
          <step-enviar-recurso
            v-if="currStep === 8"
            ref="step8"
            :infracao="infracao"
            :recurso="recurso"
          />
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-centered">
          <div class="column is-narrow">
            <b-button
              v-show="currStep > 1 && currStep < 8"
              type="is-verde"
              class="level-item button-width"
              @click="previousStep"
            >
              Voltar
            </b-button>
          </div>
          <div class="column is-narrow">
            <b-button
              v-show="currStep < 8"
              type="is-primary"
              class="level-item button-width"
              @click="nextStep"
            >
              Proximo
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StepCadastroDados from '../../../../components/infracao/recurso/step-cadastro-dados.vue'
import StepUploadImagem from '../../../../components/infracao/recurso/step-upload-imagem.vue'
import StepConfirmar from '../../../../components/infracao/recurso/step-confirmar.vue'
import StepUploadImagemDocumento from '../../../../components/infracao/recurso/step-upload-imagem-documento.vue'
import StepEnviarRecurso from '../../../../components/infracao/recurso/step-enviar-recurso.vue'

export default {
  name: 'SolicitacaoRecurso',
  components: {
    StepCadastroDados,
    StepUploadImagem,
    StepConfirmar,
    StepEnviarRecurso,
    StepUploadImagemDocumento
  },
  layout: process.env.VUE_APP_LAYOUT_DEFAULT,
  validate ({ params }) {
    return /^\d+$/.test(params.infracao_id)
  },
  data () {
    return {
      infracao: {},
      currStep: 1,
      tiposSolicitacaoRecurso: {},
      recurso: {
        ait: '',
        placa: '',
        imagens: {
          docIdentificacao: null,
          docNotifOuPenalidade: null,
          formularioAssinado: null,
          docResponsabilidade: null,
          docComprobatorio: null
        }
      },
      steps: [
        {
          id: 1,
          label: '1',
          isActive: true,
          isCompleted: false,
          detail: 'Cadastro de dados',
          validate: el => el.$refs.step1.validate()
        },
        {
          id: 2,
          label: '2',
          isActive: false,
          isCompleted: false,
          detail: 'Documento de Identificação',
          validate: el => el.$refs.step2.validate()
        },
        {
          id: 3,
          label: '3',
          isActive: false,
          isCompleted: false,
          detail: 'Documento da Notificação de Autuação ou Notificação de Penalidade',
          validate: el => el.$refs.step3.validate()
        },
        {
          id: 4,
          label: '4',
          isActive: false,
          isCompleted: false,
          detail: 'Formulário Assinado',
          validate: el => el.$refs.step4.validate()
        },
        {
          id: 5,
          label: '5',
          isActive: false,
          isCompleted: false,
          detail: 'Documento de Responsabilidade',
          validate: el => el.$refs.step5.validate()
        },
        {
          id: 6,
          label: '6',
          isActive: false,
          isCompleted: false,
          detail: 'Documento Comprobatório',
          validate: el => el.$refs.step6.validate()
        },
        {
          id: 7,
          label: '7',
          isActive: false,
          isCompleted: false,
          detail: 'Conferir',
          validate: el => el.$refs.step7.validate()
        },
        {
          id: 8,
          label: '8',
          isActive: false,
          isCompleted: false,
          detail: 'Enviar',
          validate: el => el.$refs.step8.validate()
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getInfracao: 'infracoes/infracao'
    })
  },
  async beforeMount () {
    this.getDados()
    const infracaoId = Number(this.$route.params.infracao_id)
    const infracao = await this.getInfracao(infracaoId)
    debugger
    if (infracao == null) {
      this.returnIndexPage()
    } else {
      this.recurso.ait = infracao.ait
      this.recurso.placa = infracao.placa
    }

    this.infracao = infracao
  },
  methods: {
    ...mapActions('infracoes', [
      'getInfracoes'
    ]),
    getDados () {
      let hash = this.$store.getters['sessions/hash']

      if (hash === '' || hash === null) {
        hash = localStorage.getItem('placa-renavam-hash')
        this.$store.commit('session/SESSION_SUCCESS', hash)
      }

      return this.getInfracoes(hash)
    },
    changeCurrStep (next) {
      this.currStep = this.steps.length + 1 > next ? next : 1
      this.steps.forEach((step) => {
        step.isActive = step.id === this.currStep
        step.isCompleted = step.id < next
      })
    },
    nextStep () {
      this.steps[this.currStep - 1].validate(this).then((ret) => {
        if (ret) {
          this.changeCurrStep(this.currStep + 1)
        }
      })
    },
    semImagem (value) {
      this.recurso.semDocResponsabilidade = value
    },
    semImagemComprobatorio (value) {
      this.recurso.semDocComprobatorio = value
    },
    previousStep () {
      this.changeCurrStep(this.currStep - 1)
    },
    returnIndexPage () {
      this.$buefy.dialog.alert({
        message:
          'Sua sessão expirou. Por gentileza, consulte a placa novamente',
        confirmText: 'Retornar',
        onConfirm: () => {
          this.$store.commit('infracoes/limpaInfracoes')
          localStorage.clear()
          this.$router.push('/')
        }
      })
    },
    cancel () {
      this.$buefy.dialog.confirm({
        message: 'Tem certeza de que deseja cancelar a indicação de condutor?',
        confirmText: 'Sair',
        onConfirm: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>
<style>
  .button-width {
    width: 100px;
  }
</style>
