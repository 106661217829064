
export function validaCNH (cnh) {
  let isValid = false

  if (saoDigitosIguais(cnh)) {
    return false
  }

  if (cnh.length === 11) {
    let dsc = 0
    let v = 0
    for (let i = 0, j = 9; i < 9; i++, j--) {
      v += Number(cnh.charAt(i)) * j
    }

    let validateDgt11 = v % 11
    if (validateDgt11 >= 10) {
      validateDgt11 = 0
      dsc = 2
    }

    v = 0
    for (let i = 0, j = 1; i < 9; i++, j++) {
      v += Number(cnh.charAt(i)) * j
    }

    const x = v % 11
    const validateDgt12 = (x >= 10) ? 0 : x - dsc

    isValid = validateDgt11.toString() + validateDgt12.toString() === cnh.substr(cnh.length - 2, 2)
  }

  return isValid
}

function saoDigitosIguais (cnh) {
  if (cnh === '11111111111' || cnh === '22222222222' || cnh === '33333333333' ||
    cnh === '44444444444' || cnh === '55555555555' || cnh === '66666666666' ||
    cnh === '77777777777' || cnh === '88888888888' || cnh === '99999999999' ||
    cnh === '00000000000') {
    return true
  }
  return false
}
